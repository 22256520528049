import React from 'react';
/* global adsbygoogle */ // adsbygoogle'ı global olarak tanımlayın

const AdComponent = () => {
    return (
        <div>
            {/* Reklam Birimi Kodu */}
            {/* <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6747795568743803" // AdSense Client ID
                data-ad-slot="XXXXXXXXXX" // Buraya reklam birimi ID'nizi ekleyin
                data-ad-format="auto"></ins>
            <script>
                {(adsbygoogle = window.adsbygoogle || []).push({})}
            </script> */}
        </div>
    );
};

export default AdComponent;
