import React, { useState, useEffect, Suspense, useCallback } from 'react';
import { HashRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Container, Box } from '@mui/material';
import ReactGA from 'react-ga4';
import AdComponent from './Components/AdComponent'; // AdComponent'i import edin

// Lazy loading ile bileşenleri import et
const Universite = React.lazy(() => import('./Universite'));
const LGS = React.lazy(() => import('./LGS'));
const KPSS = React.lazy(() => import('./KPSS'));
const Dgs = React.lazy(() => import('./DGS'));
const ALES = React.lazy(() => import('./ALES'));
const YDS = React.lazy(() => import('./YDS'));
const UNOH = React.lazy(() => import('./UNOH'));

const TRACKING_ID = "G-8RLCKYZNM5"; // Kendi ölçüm kimliğinizi buraya ekleyin

function App() {
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation(); // useLocation burada kullanılacak

  // useCallback ile handleScroll fonksiyonunu sarmalayın
  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      setShowHeader(false); // Aşağı kaydırma
    } else {
      setShowHeader(true); // Yukarı kaydırma
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]); // lastScrollY bağımlılığı

  // GA4'ü başlat
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []); // Sadece bir kez çalıştırmak için boş bağımlılık dizisi

  // Sayfa görüntüleme takibi
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]); // Sayfa değişimlerinde çalışır

  // Scroll olay dinleyicisini ekleme
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]); // handleScroll'u bağımlılık dizisine ekleyin

  return (
    <div>
      <AppBar position="fixed" style={{ transition: 'top 0.3s', top: showHeader ? '0' : '-64px' }}>
        <Toolbar>
          <Box display="flex" flexWrap="wrap" overflow="auto" width="100%">
            <Button color="inherit" component={Link} to="/Universite">Üniversite</Button>
            <Button color="inherit" component={Link} to="/LGS">LGS</Button>
            <Button color="inherit" component={Link} to="/KPSS">KPSS</Button>
            <Button color="inherit" component={Link} to="/DGS">DGS</Button>
            <Button color="inherit" component={Link} to="/ALES">ALES</Button>
            <Button color="inherit" component={Link} to="/YDS">YDS</Button>
            <Button color="inherit" component={Link} to="/UNOH">ÜNOH</Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Sayfa Geçişleri - İçerik Alanı */}
      <Container style={{ marginTop: '64px' }}>
        <Suspense fallback={<div>Sayfa yükleniyor...</div>}>
        <AdComponent /> {/* Reklam Bileşenini burada ekleyin */}
          <Routes>
            <Route path="/Universite" element={<Universite />} />
            <Route path="/LGS" element={<LGS />} />
            <Route path="/KPSS" element={<KPSS />} />
            <Route path="/DGS" element={<Dgs />} />
            <Route path="/ALES" element={<ALES />} />
            <Route path="/YDS" element={<YDS />} />
            <Route path="/UNOH" element={<UNOH />} />
            <Route path="/" element={<Universite />} />
          </Routes>
        </Suspense>
      </Container>
    </div>
  );
}

// Router bileşenini dışarıda tutarak ana bileşeni oluşturun
const Root = () => (
  <Router>
    <App />
  </Router>
);

export default Root;
